import { AbortButton, Button, FlexRow } from '@greenbone/cloud-component-library'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Legal from './slides/Legal'
import CompanyInfos from './slides/CompanyInfos'
import SignUp from './slides/SignUp'
import { ContentContainer, FadeInWrapper, GreenboneLogo, InfoContainer } from './StyledComponents'
import { getLocalStorageData, removeLocalStorageData, setLocalStorageData } from './utils'
import { SLIDES, SLIDE_FORM_MAP } from './constants'
import { Success } from './slides/Success'
import { Footer } from './Footer.js'
import { FreeInfo } from './FreeInfo'
import { OrderSuccessIllustrationTemplate } from '../assets/SvgTemplate'
import LogoReport from '../../../../assets/logos/logoReport.png'
import _ from 'lodash';

const RegisterSlides = ({
        initialSlideIndex = SLIDES.SIGN_UP,
        ...props
    }) => {

    const storedSlideIndexString = getLocalStorageData("slideIndex")
    const [slideIndex, setSlideIndex] = useState(storedSlideIndexString || SLIDES.SIGN_UP)
    const [invertedTransition, setInvertedTransition] = useState()
    const slideRef = useRef()
    const {t} = useTranslation()

    const firstInvalidFieldMap = useMemo(() => {
        if (!props.fieldErrors) return null
        const invalidFields = Object.keys(props.fieldErrors)
        
        return SLIDE_FORM_MAP.find(config => {
            return invalidFields.some(fieldName => config.fields.includes(fieldName))
        })
    }, [props.fieldErrors])

    useEffect(() => {
        if (firstInvalidFieldMap) {
            updateSlideIndex(firstInvalidFieldMap.slideIndex, true)
        }
    }, [props.fieldErrors, firstInvalidFieldMap])

    const updateSlideIndex = (index, invertedTransition = false) => {
        setSlideIndex(index)
        setLocalStorageData("slideIndex", index)
        setInvertedTransition(invertedTransition)
    }

    const handleNextClick = () => {
        slideRef.current?.validate().then(result => {
            if (result !== true) return
    
            if (slideIndex === SLIDES.LEGAL) {
                debouncedHandleSubmit();
                return
            }
            updateSlideIndex(slideIndex + 1)
        })

        if (slideIndex === SLIDES.SUCCESS) {
            window.location = '/'
        }
    }

    const debouncedHandleSubmit = _.debounce(() => {
        props.handleSubmit().then(hasSucceeded => {
            if (hasSucceeded) {
                setSlideIndex(SLIDES.SUCCESS);
            }
        });
    }, 500);

    const handlePreviousClick = () => {
        updateSlideIndex(slideIndex - 1, true)
    }

    const handleBackToLoginClick = () => {
        removeLocalStorageData('slideIndex')
        removeLocalStorageData('billingData')
        removeLocalStorageData('loginData')
        window.location = '/'
    }

    const buttonTextValues = [
        t("extern.registration.RegisterContainer.stepNext"),
        t("extern.registration.RegisterContainer.stepNext"),
        t("extern.registration.RegisterContainer.createFreeAccount"),
        t("loginText"),
    ]   

    const {
        loginData,
        loginDataValid,
        handleLoginDataChange,
        handleBillingAddressChange,
        billingAddress,
        billingAddressValid,
        handleLegalChange,
        legal,
        legalValid,
        countries,
        validateLoginData,
        validateBillingAddress,
        validateTerms,
        fieldErrors
    } = props

    function renderComponent() {
        switch (slideIndex) {
            case SLIDES.SIGN_UP:
                return <FadeInWrapper key={slideIndex} invertedTransition={invertedTransition}>
                            <SignUp
                                loginData={loginData}
                                loginDataValid={loginDataValid}
                                onChange={handleLoginDataChange}
                                ref={slideRef}
                                validateLoginData={validateLoginData}
                                initiallyShowErrors={!!fieldErrors}
                            />
                    </FadeInWrapper>
                
            case SLIDES.COMPANY_INFO:
                return <FadeInWrapper key={slideIndex} invertedTransition={invertedTransition}>
                            <CompanyInfos
                                countries={countries}
                                billingAddress={billingAddress}
                                billingAddressValid={billingAddressValid}
                                onChange={handleBillingAddressChange}
                                ref={slideRef}
                                validateBillingAddress={validateBillingAddress}
                                initiallyShowErrors={!!fieldErrors}
                            />
                        </FadeInWrapper>
                
            case SLIDES.LEGAL:
                return <FadeInWrapper key={slideIndex} invertedTransition={invertedTransition}>
                        <Legal
                            legal={legal}
                            legalValid={legalValid}
                            onChange={handleLegalChange}
                            ref={slideRef}
                            validateTerms={validateTerms}
                            initiallyShowErrors={!!fieldErrors}
                        />
                    </FadeInWrapper>
            case SLIDES.SUCCESS:
                return <FadeInWrapper key={slideIndex} invertedTransition={invertedTransition}>
                        <Success />
                    </FadeInWrapper>
            default:
                return null
        }
    }

    const showPreviousButton = slideIndex !== SLIDES.SIGN_UP && slideIndex !== SLIDES.SUCCESS

    return (
        <>
            <InfoContainer style={{
                justifyContent: slideIndex === SLIDES.SUCCESS ? "flex-end" : "center",
            }}>
                    {
                        slideIndex === SLIDES.SUCCESS ?
                        <OrderSuccessIllustrationTemplate
                        style={{zIndex: 1, position: "relative", width: "calc(100% + 100px)", bottom: 0}}
                        /> :
                        <FreeInfo />

                    }
            </InfoContainer>
            <ContentContainer invertedAnimation={true}>
                <FlexRow justifyContent={"space-between"} fullWidth>
                    <GreenboneLogo src={LogoReport} alt="Greenbone Vulnerability Management"/>
                    <AbortButton onClick={handleBackToLoginClick} style={{width: "25%", height: "36px"}}>
                        {t("backToLoginText")}
                    </AbortButton>
                </FlexRow>
                <div
                    style={{ width: '100%', overflow: "none" }}
                >
                    {renderComponent()}
                    <FlexRow justifyContent={"space-between"}>
                        {showPreviousButton &&
                            <AbortButton onClick={handlePreviousClick} style={{width: "30%"}}>
                                {t("extern.registration.RegisterContainer.stepBack")}
                            </AbortButton>
                        }
                        <Button onClick={handleNextClick} style={{width: showPreviousButton ? "50%" : "100%"}}>
                            {buttonTextValues[slideIndex]}
                        </Button>
                    </FlexRow>
                </div>
                <Footer />
            </ContentContainer>
        </>
    )
}

export default RegisterSlides
